@font-face {
    font-family: 'IBMPlexMono';
    src: url('../../public/fonts/IBMPlexMono/IBMPlexMono-Thin.ttf');
    font-weight: 100;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('../../public/fonts/IBMPlexMono/IBMPlexMono-ExtraLight.ttf');
    font-weight: 200;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('../../public/fonts/IBMPlexMono/IBMPlexMono-Light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('../../public/fonts/IBMPlexMono/IBMPlexMono-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('../../public/fonts/IBMPlexMono/IBMPlexMono-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('../../public/fonts/IBMPlexMono/IBMPlexMono-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('../../public/fonts/IBMPlexMono/IBMPlexMono-Bold.ttf');
    font-weight: 700;
}
